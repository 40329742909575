import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@core/environments/environment';
import { Pagination } from '@core/models/pagination.model';

@Injectable({
  providedIn: 'root',
})
export class ApprovalService {
  http = inject(HttpClient);
  BASE_URL = environment.ADMIN_BASE_URL + '/v1/kyc';

  constructor() {}

  getDocumentsList(params: Pagination = new Pagination()) {
    return this.http.get(
      this.BASE_URL +
        `/get-kyc-document-list?take=${params.size}&skip=${params.skip}`,
      {
        headers: {
          Authorization: 'Bearer allow',
        },
      }
    );
  }


  filterDocumentsList(queryParams: string) {
    return this.http.get(
      this.BASE_URL + `/search-kyc-documents?${queryParams}`,
      {
        headers: {
          Authorization: 'Bearer allow',
        },
      }
    );
  }

  getDocumentsListCount(status: string = 'pending') {
    return this.http.get(
      this.BASE_URL + `/get-kyc-document-list-by-status?status=${status}`,
      {
        headers: {
          Authorization: 'Bearer allow',
        },
      }
    );
  }
  getDocumentsMetaData() {
    return this.http.get(
      this.BASE_URL + `/get-document-meta-data`,
    );
  }

  rejectDocument(documentId: string) {
    const payload = {
      status: 'rejected',
      rejection_reason: 'The document is blurry',
      document_id: documentId,
    };
    return this.http.patch(
      this.BASE_URL + `/update-kyc-document-status`,
      payload
    );
  }

  approveDocument(documentId: string) {
    const payload = {
      status: 'approved',
      rejection_reason: 'The document meets all criteria',
      document_id: documentId,
    };
    return this.http.patch(
      this.BASE_URL + `/update-kyc-document-status`,
      payload
    );
  }
}
