import { Selector } from '@ngxs/store';
import { AuthState, AuthStateModel } from './auth.state';

export class AuthSelectors {
  @Selector([AuthState])
  static getLoadingStatus(state: AuthStateModel): boolean | null {
    return state.loading;
  }

  @Selector([AuthState])
  static getAuthToken(state: AuthStateModel): string | null {
    return state.token;
  }

  @Selector([AuthState])
  static getSessionStatus(state: AuthStateModel): boolean | null {
    return state.isSessionValid;
  }

  @Selector([AuthState])
  static getTokenExpiredUrl(state: AuthStateModel): string | null {
    return state.tokenExpiredUrl;
  }
 
  
    @Selector([AuthState])
    static getApprovals(state: AuthStateModel): any[] {
      return state.approval.approvals;
    }
  
    @Selector([AuthState])
    static getApprovalLoading(state: AuthStateModel): boolean {
      return state.approval.loading;
    }
  
    @Selector([AuthState])
    static getApprovalError(state: AuthStateModel): string | null {
      return state.approval.error;
    }
}
