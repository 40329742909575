import { Injectable, NgZone, inject } from '@angular/core';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { AuthActions } from './auth.actions';
import { AuthService } from '@core/services/auth.service';
import { Router } from '@angular/router';
import { TOKEN } from 'app/app.constants';
import { NotificationFacade } from '@core/facades/notification.facade';
import { IAuthStateModel,ApprovalStateModel, } from './auth.state.model';
import { ApprovalService } from '@core/services/approval.service';
import { Pagination } from '@core/models/pagination.model';
export interface AuthStateModel {
  token: string | null;
  loading: boolean | null;
  isSessionValid: boolean | null;
  tokenExpiredUrl: string | null;
  hasApiError: boolean | null;
  resetPasswordSuccess: boolean | null;
  forgotPasswordSuccess: boolean | null;
  auth: IAuthStateModel;
  approval: ApprovalStateModel;
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    token: null,
    loading: false,
    isSessionValid: null,
    tokenExpiredUrl: null,
    hasApiError: null,
    resetPasswordSuccess: null,
    forgotPasswordSuccess: null,
  
    auth: {
      token: null,
      loading: false,
      error: null,
      resetPasswordSuccess: null,
      forgotPasswordSuccess: null,
    },
    approval: {
      approvals: [],
      loading: false,
      error: null,
    },
  },
})

@State<ApprovalStateModel>({
  name: 'approval',
  defaults: {
    approvals: [],
    loading: false,
    error: null,
  },
})

@Injectable()
export class AuthState {
  authService = inject(AuthService);
  constructor(private approvalService: ApprovalService) {}
  store = inject(Store);
  ngZone = inject(NgZone);
  router = inject(Router);
  toast = inject(NotificationFacade);
  static approval(state: AuthStateModel) {
    return state.approval;
  }

  

  @Action(AuthActions.Login)
  login(ctx: StateContext<AuthStateModel>, action: AuthActions.Login) {
    this.store.dispatch(new AuthActions.SetLoadingStatus(true));

    const { email, password } = action.payload;
    this.authService.login({ email, password }).subscribe({
      next: (response) => {
        this.store.dispatch(new AuthActions.SetLoadingStatus(false));
        this.store.dispatch(new AuthActions.SetTokenValue(response.token));
        this.store.dispatch(new AuthActions.SetSessionStatus(true));
        ctx.patchState({
          token: response.token,
          loading: false,
          isSessionValid: true,
          hasApiError: false,
        });

        this.toast.success('Login successful');
        localStorage.setItem(TOKEN, response.token);

        this.ngZone.run(async () =>
          this.router.navigateByUrl('/admin/dashboard')
        );
      },
      error: (resp) => {
        this.store.dispatch(new AuthActions.SetLoadingStatus(false));
        ctx.patchState({
          token: null,
          loading: false,
          isSessionValid: false,
          tokenExpiredUrl: null,
          hasApiError: null,
        });
      },
    });
  }

  @Action(AuthActions.SetLoadingStatus)
  setLoadingStatus(
    ctx: StateContext<AuthStateModel>,
    action: AuthActions.SetLoadingStatus
  ) {
    ctx.patchState({
      loading: action.loading,
    });
  }

  @Action(AuthActions.SetSessionStatus)
  setSessionStatus(
    ctx: StateContext<AuthStateModel>,
    action: AuthActions.SetSessionStatus
  ) {
    ctx.patchState({
      token: null,
      isSessionValid: action.payload,
    });
  }

  @Action(AuthActions.SetTokenExpiredUrl)
  setTokenExpiredUrl(
    ctx: StateContext<AuthStateModel>,
    action: AuthActions.SetTokenExpiredUrl
  ) {
    ctx.patchState({
      tokenExpiredUrl: action.payload,
    });
  }

  @Action(AuthActions.ResetState)
  resetAuthState(ctx: StateContext<AuthStateModel>) {
    ctx.patchState({
      token: null,
      loading: false,
      isSessionValid: false,
      tokenExpiredUrl: null,
      hasApiError: null,
    });
  }

  @Action(AuthActions.SetTokenValue)
  setTokenValue(
    ctx: StateContext<AuthStateModel>,
    action: AuthActions.SetTokenValue
  ) {
    ctx.patchState({
      token: action.token,
    });
  }
  @Action(AuthActions.LoadApprovals)
  loadApprovals(ctx: StateContext<ApprovalStateModel>, action: AuthActions.LoadApprovals) {
    ctx.patchState({ loading: true });

    this.approvalService.getDocumentsList(action.payload.params).subscribe({
      next: (approvals) => {
        ctx.dispatch(new AuthActions.LoadApprovalsSuccess({ approvals: approvals as any[] }));
      },
      error: (error) => {
        ctx.dispatch(new AuthActions.LoadApprovalsFailure({ error: error.message }));
      },
    });
  }

  @Action(AuthActions.LoadApprovalsSuccess)
  loadApprovalsSuccess(ctx: StateContext<ApprovalStateModel>, action: AuthActions.LoadApprovalsSuccess) {
    ctx.patchState({
      approvals: action.payload.approvals,
      loading: false,
      error: null,
    });
  }

  @Action(AuthActions.LoadApprovalsFailure)
  loadApprovalsFailure(ctx: StateContext<ApprovalStateModel>, action: AuthActions.LoadApprovalsFailure) {
    ctx.patchState({
      loading: false,
      error: action.payload.error,
    });
  }

  @Action(AuthActions.ApproveApproval)
  approveApproval(ctx: StateContext<ApprovalStateModel>, action: AuthActions.ApproveApproval) {
    this.approvalService.approveDocument(action.payload.approvalId).subscribe({
      next: () => {
        const approvals = ctx.getState().approvals.map((approval) =>
          approval.id === action.payload.approvalId ? { ...approval, status: 'approved' } : approval
        );
        ctx.patchState({ approvals });
      },
      error: (error) => {
        console.error('Failed to approve approval:', error);
      },
    });
  }

  @Action(AuthActions.RejectApproval)
  rejectApproval(ctx: StateContext<ApprovalStateModel>, action: AuthActions.RejectApproval) {
    this.approvalService.rejectDocument(action.payload.approvalId).subscribe({
      next: () => {
        const approvals = ctx.getState().approvals.map((approval) =>
          approval.id === action.payload.approvalId ? { ...approval, status: 'rejected' } : approval
        );
        ctx.patchState({ approvals });
      },
      error: (error) => {
        console.error('Failed to reject approval:', error);
      },
    });
  }
}