

import { Pagination } from "@core/models/pagination.model";

const ACTION_SCOPE = '[Auth]';
export namespace AuthActions {
  export class Login {
    static readonly type = `${ACTION_SCOPE} Login`;
    constructor(public payload: { email: string; password: string }) {}
  }

  export class SetLoadingStatus {
    static readonly type = `${ACTION_SCOPE} Set Loading Status`;
    constructor(public loading: boolean) {}
  }

  export class SetSessionStatus {
    static readonly type = `${ACTION_SCOPE} Set Session Status`;
    constructor(public payload: boolean) {}
  }

  export class SetTokenExpiredUrl {
    static readonly type = `${ACTION_SCOPE} Set Token Expired Url`;
    constructor(public payload: string) {}
  }

  export class ResetState {
    static readonly type = `${ACTION_SCOPE} Reset Auth State`;
    constructor() {}
  }

  export class SetTokenValue {
    static readonly type = `${ACTION_SCOPE} Set Token Value`;
    constructor(public token: string) {}
  }

  export class HasApiError {
    static readonly type = `${ACTION_SCOPE} Set Api Error Status`;
    constructor(public token: boolean) {}
  }

  export class ResetPassword {
    static readonly type = `${ACTION_SCOPE} Reset Password`;
    constructor(public payload: {token: string; newPassword: string}) {}
  }

  export class ForgotPassword {
    static readonly type = `${ACTION_SCOPE} Forgot Password`;
    constructor(public payload: {email: string}) {}
  }

  export class SetAuthLoading {
    static readonly type = `${ACTION_SCOPE} Set Auth Loading`;
    constructor(public payload: {loading: boolean}) {}
  }
  export class SetAuthError {
    static readonly type = `${ACTION_SCOPE} Set Auth Error`;
    constructor(public payload: {error: string | null}) {}
  }


  export class LoadApprovals {
    static readonly type = `${ACTION_SCOPE} Load Approvals`;
    constructor(public payload: { params: Pagination }) {}
  }

  export class LoadApprovalsSuccess {
    static readonly type = `${ACTION_SCOPE} Load Approvals Success`;
    constructor(public payload: { approvals: any[] }) {}
  }

  export class LoadApprovalsFailure {
    static readonly type = `${ACTION_SCOPE} Load Approvals Failure`;
    constructor(public payload: { error: string }) {}
  }

  export class ApproveApproval {
    static readonly type = `${ACTION_SCOPE} Approve Approval`;
    constructor(public payload: { approvalId: string }) {}
  }

  export class RejectApproval {
    static readonly type = `${ACTION_SCOPE} Reject Approval`;
    constructor(public payload: { approvalId: string }) {}
  }
}